import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.page.html',
  styleUrls: ['./calendario.page.scss'],
})
export class CalendarioPage implements OnInit {

  title:string = "";
  @Input() tipo:any;
  fecha_hora:any;
  dateValue:any;

  constructor( public modalCtrl:ModalController ) { }

  ngOnInit() {
    if( this.tipo == 1 ){
      this.title = "inicial";
    }else{
      this.title = "final";
    }
  }

  seleccionar(){
    console.log(this.fecha_hora);
    this.modalCtrl.dismiss({ tipo:this.tipo,fecha: moment(this.fecha_hora).format("YYYY-MM-DD HH:mm"), timestamp: moment(this.fecha_hora).unix() });
  }

  formatDate(value:any){
    console.log(value);
    this.fecha_hora = value;
  }

}
